<script lang="ts" setup>
import {
  CheckIcon,
  CopyIcon,
} from 'lucide-vue-next';
import { type HTMLAttributes } from 'vue';

const TIMEOUT_MILLISECONDS = 3000;

const props = defineProps<{
  class?: HTMLAttributes['class'];
  isDarkBackground?: boolean;
  promoCode: string;
}>();

const delegatedProps = computed(() => {
  const {
    class: _,
    ...delegated
  } = props;

  return delegated;
});

const copied = ref(false);

const { copy } = useClipboard();

const copyPromoCode = async () => {
  await copy(props.promoCode);

  copied.value = true;

  setTimeout(() => {
    copied.value = false;
  }, TIMEOUT_MILLISECONDS);
};
</script>

<template>
  <button
    :class="cn(
      'flex items-center justify-center rounded-md leading-none border border-dashed px-3 py-1 text-sm font-semibold lg:py-2',
      isDarkBackground ? 'text-white border-white/50  hover:border-white' : 'text-dark border-dark/50 hover:border-dark',
      props.class
    )"
    v-bind="delegatedProps"
    @click="copyPromoCode()"
  >
    <template v-if="copied">
      {{ $t('common.copied') }}
      <CheckIcon class="ml-2 size-5 rounded-full bg-white stroke-[3px] p-1 text-green" />
    </template>

    <template v-else>
      {{ promoCode }}
      <CopyIcon class="ml-2 size-4" />
    </template>
  </button>
</template>
